.select {
    padding: 20px;
    font-size: 1rem;
    min-width: var(--picker-button-min-width);
    border-radius: 8px;
    border: 1px solid rgb(209 213 219);
    justify-content: center;
    position: relative;
    color: black;
}

.dropDownContainer {
    position: absolute;
    min-width: var(--picker-button-min-width);
}

.dropDownContainer * {
    color: black;
}

@media (max-width: 576px) {
    .select {
        font-size: 1rem;
    }
}