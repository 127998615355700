.button {
    --spacing-1: var(--spacing);
    --spacing-6-5: calc(var(--spacing)*6.5);
    --widget-background-color: #fff;
    --lg-font-size: 16px;
    --social-button-border-width: 1px;
    --gray-mid: #c2c8d0;
    --social-button-border-color: var(--gray-mid);
    --button-border-radius: 6px;
    --overlay-box-shadow-size: inset 0 0 0 150px;
    --transparency-hover-color: rgba(0, 0, 0, 0.1);
    --button-hover-shadow: var(--overlay-box-shadow-size) var(--transparency-hover-color);
    --spacing: 8px;
    display: flex;
    position: relative;
    --calre: calc(var(--spacing-6-5)/2);
    padding: 0 calc(var(--calre) - 10px) 0 var(--spacing-6-5);
    background: var(--widget-background-color);
    align-items: center;
    font-size: var(--lg-font-size);
    font-family: inherit;
    text-align: center;
    height: var(--spacing-6-5);
    border: var(--social-button-border-width) solid var(--social-button-border-color);
    border-radius: var(--button-border-radius);
    cursor: pointer;
    outline: 0;
    color: black;
}

.logout-button{
    display: flex;
    padding: 0 .7rem;
    height: 2rem;
    font-size: .9rem;
    gap: .5rem;
}

.logout-button svg {
    stroke: black;
}

.text {
    transform: translateY(-2px);
    color: black;
}