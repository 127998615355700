.button {
    min-width: var(--picker-button-min-width);
    display: inline-block;
    background-color: var(--primary-color);
    /* Green color */
    color: white;
    /* Text color */
    padding: 20px;
    /* Top/bottom and left/right padding */
    border: none;
    border-radius: 8px;
    /* Rounded corners */
    transition: background-color 0.3s;
    /* Transition effect for hover */
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.5125rem;
}

.button:hover {
    background-color: rgb(34 197 94);
    cursor: pointer;
    /* Darker green on hover */
}

.button-action {
    background-color: var(--primary-color);
}

.button-action:hover {
    background-color: rgb(34 197 94);
}

.button-info {
    background-color: #4184a9;
}

.button-info:hover {
    background-color: #32627c;
}

.button-plain {
    color: black;
    background-color: white;
    border: 1px solid #c2c8d0;
    border-radius: 6px;
    font-size: 1rem;
    padding: 10px;
}

.button-plain:hover {
    background-color: rgb(235, 235, 235);
}



.button-disabled {
    background-color: #D9D9D9;
    color: #8b8b8b
}

.button-disabled:hover {
    background-color: #D9D9D9;
    cursor: default;
}

@media (max-width: 576px) {
    .button {
        font-size: 1.25rem;
    }

    .button-plain {
        font-size: 1rem;
    }
}