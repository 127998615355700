.container {
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.infoContainer {
    margin-top: 1rem;
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
    max-width: 400px;
}
.title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.qrCodeContainer {
    margin-bottom: 1rem;
}

.qrCode {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
}

.buttonContainer {
    display: flex;
    width: 100%;
    max-width: 400px;
    gap: 1rem;
    flex-direction: column;
}

.usedButton {
    background-color: #4caf50;
    color: white;
    width: 100%;
    font-weight: bold;
    font-size: 1rem;
    padding: 1rem 1.5rem; /* Adjusted padding for a slightly bigger button */
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
}

.usedButton:disabled {
    background-color: #4caf50;
    color: white;
    opacity: 0.5;
    cursor: not-allowed;
}

.sharedButton {
    background-color: #CA8A04;
    color: white;
    width: 100%;
    font-weight: bold;
    font-size: 1rem;
    padding: 1rem 1.5rem; /* Adjusted padding for a slightly bigger button */
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
}

.sharedButton:disabled {
    background-color: #CA8A04;
    color: white;
    opacity: 0.5;
    cursor: not-allowed;
}

.notWorkingButton {
    width: 100%;
    background-color: #e3342f;
    color: white;
    font-weight: bold;
    font-size: 1rem;
    padding: 1rem 1.5rem; /* Adjusted padding for a slightly bigger button */
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
}

.notWorkingButton:disabled {
    background-color: #e3342f;
    color: white;
    opacity: 0.5;
    cursor: not-allowed;
}


.modalContent {
    margin-bottom: 20px;
    width: 100%;
}

.modalButtons {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 10px;
}

.modalContent button {
    padding: 10px 30px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.2rem;
}

/* Updated Modal Content Styles */
.modalContent {
    font-size: 1.2rem;
}

/* Updated Modal Buttons Styles */
.modalContent button {
    background-color: #4caf50;
    color: white;
    font-weight: bold;
    margin-right: 10px;
}

.modalContent button:last-child {
    background-color: #e74c3c;
}

.emailLink {
    color: #4caf50;
    text-decoration: none;
    font-weight: bold;
}