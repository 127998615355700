.header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 1.5rem;
}

.title {
    font-size: 1.52125rem;
    margin-left: 1rem;
}

.logo {
    height: 3.9375rem;
}

@media (max-width: 576px) {
    .title {
        font-size: 1.27706rem;
    }
}