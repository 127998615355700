.filepicker {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    --picker-button-min-width: 250px;
}

.preview-image {
    max-width: 50%;
    max-height: 35rem;
}

.input-file {
    display: none;
}

.error-text {
    color: #cc0000;
    background-color: rgba(255, 255, 255, .5);
    padding: 1rem;
    border-radius: 8px;
    text-align: center;
}

.section-title {
    font-size: 2rem;
    font-weight: 600;
}

@media (max-width: 576px) {
    .preview-image {
        max-width: 100%;
        max-height: 15rem;
    }

    .section-title {
        font-size: 1.5rem;
    }
}