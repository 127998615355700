/* reCAPTCHA Styles */
.recaptcha {
    margin: 1rem 0;
    display: flex;
    justify-content: center;
}

/* Mobile Responsive */
@media (max-width: 768px) {
    .recaptcha {
        margin: 0.5rem 0;
    }

}