/* General App Styles */
.App {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
    text-align: center;
    background-color: #282c34;
    padding-left: 10px;
    padding-right: 10px;
}

.App-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    color: white;
}

/* Title */
.title {
    font-size: 2rem;
    margin-bottom: 1rem;
}

/* Mobile Responsive */
@media (max-width: 768px) {
    .App-header {
        padding: 0.5rem;
    }

    .title {
        font-size: 1.5rem;
    }

    .google-button {
        padding: 0.25rem 0.5rem;
    }

    .google-icon {
        margin-right: 0.25rem;
    }
}