.button {
    --spacing-1: var(--spacing);
    --spacing-6-5: calc(var(--spacing)*6.5);
    --widget-background-color: #fff;
    --lg-font-size: 16px;
    --social-button-border-width: 1px;
    --gray-mid: #c2c8d0;
    --social-button-border-color: var(--gray-mid);
    --button-border-radius: 6px;
    --overlay-box-shadow-size: inset 0 0 0 150px;
    --transparency-hover-color: rgba(0, 0, 0, 0.1);
    --button-hover-shadow: var(--overlay-box-shadow-size) var(--transparency-hover-color);
    --spacing: 8px;
    display: flex;
    position: relative;
    --calre: calc(var(--spacing-6-5)/2);
    padding: 0 calc(var(--calre) - 10px) 0 var(--spacing-6-5);
    background: var(--widget-background-color);
    align-items: center;
    font-size: var(--lg-font-size);
    font-family: inherit;
    text-align: center;
    height: var(--spacing-6-5);
    border: var(--social-button-border-width) solid var(--social-button-border-color);
    border-radius: var(--button-border-radius);
    cursor: pointer;
    outline: 0;
}

.button .c8cb4305a {
    position: absolute;
    left: calc(var(--spacing-6-5)/2);
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.c8cb4305a {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 48 48'%3E%3Cdefs%3E%3Cpath id='a' d='M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z'/%3E%3C/defs%3E%3CclipPath id='b'%3E%3Cuse xlink:href='%23a' overflow='visible'/%3E%3C/clipPath%3E%3Cpath clip-path='url(%23b)' fill='%23FBBC05' d='M0 37V11l17 13z'/%3E%3Cpath clip-path='url(%23b)' fill='%23EA4335' d='M0 11l17 13 7-6.1L48 14V0H0z'/%3E%3Cpath clip-path='url(%23b)' fill='%2334A853' d='M0 37l30-23 7.9 1L48 0v48H0z'/%3E%3Cpath clip-path='url(%23b)' fill='%234285F4' d='M48 48L17 24l-4-3 35-10z'/%3E%3C/svg%3E");
}

.c8cb4305a {
    display: inline-block;
    width: 20px;
    height: 20px;
    position: relative;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50%;
}

.c2592ea5f {
    text-align: left;
    color: #000;
}

.button:hover {
    background-color: whitesmoke;
}